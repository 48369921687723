import { Button } from "@mui/material"
import { ElementLink, ElementLinkProps, LinkNormal } from "modules/picasso-ui/link/LinkNormal"
import { useAnalytics } from "modules/yoio/analytics/useAnalytics"
import { FC, PropsWithChildren } from "react"

export interface MarkdownLinkTrackable {
    useEvent?: boolean
    href: string
}

export interface MarkdownLinkProps extends MarkdownLinkTrackable {
    component?: 'anchor'|'button'
}

export const MarkdownLink: FC<PropsWithChildren<MarkdownLinkProps & EventButtonLinkProps & ElementLinkProps>> = ({ component = 'button', ...props }) => {

    if (component === 'button') {
        return <EventButtonLink {...props} />
    } else if (component === 'anchor') {
        return <EventLink {...props} />
    } else {
        throw new Error(`unknown component ${component}`)
    }

}

export const EventLink: FC<PropsWithChildren<MarkdownLinkTrackable>>= ({ useEvent, ...props }: MarkdownLinkTrackable) => {

    const { trackEvent } = useAnalytics()

    const handleLinkClick = () => {
        trackEvent({action:'linkClicked_'+props.href})
    }

    //@ts-ignore
    return <LinkNormal {...props} target={"_blank"} textSx={{fontSize: '1em', fontWeight: '400 !important',}} onClick={useEvent?handleLinkClick:undefined} />

}

export interface EventButtonLinkProps extends MarkdownLinkTrackable {
    fullWidth?: boolean;
    minWidth300?: boolean;
    outlined?: boolean;
    mt?: number;
    mb?: number;
    useEvent?: boolean;
}

export const EventButtonLink: FC<PropsWithChildren<EventButtonLinkProps & ElementLinkProps>> = ({children, fullWidth, minWidth300, outlined, mt, mb, useEvent, ...props}) => {
   
    const { trackEvent } = useAnalytics()

    const handleLinkClick = () => {
        trackEvent({action:'linkClicked_'+props.href})
    }

    return <ElementLink {...props} href={props.href} prefetch={false} sx={{marginTop: mt, marginBottom: mb, display: 'block'}}>
        {/** @ts-ignore: because of hoverInvert */}
        <Button variant={outlined?"outlined":"contained"} color="primary" size="large" hoverInvert={true} sx={[minWidth300?{minWidth:300}:null]} fullWidth={fullWidth} onClick={useEvent?handleLinkClick:undefined}>{children}</Button>
    </ElementLink>

}