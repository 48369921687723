import { Box } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { blogPageTextStyle } from 'modules/picasso-ui/present/content/styledMarkdownConfig';
import { EventButtonLink, MarkdownLink } from 'modules/yoio-modules/webpage/MarkdownComponents';
import { Fragment, ReactNode } from 'react';

export const LandingMarkdown = ({children, variant = 'standard'}:{children:string, variant?:'standard'|'blog'}) => {

    if (!children) {
        return null
    }

    const overrides = {
        'a': MarkdownLink,
        'buttonlink': EventButtonLink,
        'circle': Circle,
        'li': Li,
        'ul': Ul,
        'span': MdSpan,
        'p': MdP,
    }

    const isRealElem = variant === 'blog'
    const WrapComp = variant === 'blog' ? Box : Fragment

    const inner = <Markdown options={{overrides, namedCodesToUnicode: { '&check;': '\u2713' } }}>{children}</Markdown>

    if (isRealElem) {
        return <WrapComp css={variant==='blog'?[blogPageTextStyle]:undefined}>{inner}</WrapComp>
    } else {
        // Extra line, because css={undefined} attempts to generate a className on the fragment.
        return inner
    }
    
}

const MdSpan = ({color, mb, display, fontSize, ...props}) => {
    return <Box component="span" {...props} sx={{ color, fontSize }} mb={mb} display={display}></Box>
}

const MdP = ({color, ...props}) => {
    return <Box component="p" {...props} sx={{color}}></Box>
}

const Ul = ({primary, ...props}) => {
    return <ul {...props} 
                css={[
                    primary?theme=>({ 
                        listStyle: 'none', 
                        padding: 0,
                        marginLeft: '1em', 
                        '& li': {
                            lineHeight: '25px',
                        },
                        '& li::before': {content: '"●"', color: theme.palette.primary.main, width: '1em', marginLeft: '-1em', marginRight: '0.4em' }
                    }):null
                ]}
            />
}

const Li = (props) => {
    return <li {...props}/>
}

const Circle = () => {
    return <span>&#9679;</span>
}